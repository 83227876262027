











































.scroll-area {
  position: relative;
  margin: auto;
}
